import React, {useEffect, useLayoutEffect} from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import promoVideoMp4 from '../assets/videos/promo-video.mp4';
import promoVideoWebm from '../assets/videos/promo-video.webm';
import promoVideoPoster from '../assets/images/promo-video-poster.png';
// import promoVideoMobileMp4 from '../assets/videos/promo-video-square.mp4';
// import promoVideoMobileWebm from '../assets/videos/promo-video-square.webm';
// import promoVideoMobilePoster from '../assets/images/promo-video-square-poster.png';
// TODO: USE ACTUAL SQUARE VIDEO
import promoVideoMobileMp4 from '../assets/videos/promo-video.mp4';
import promoVideoMobileWebm from '../assets/videos/promo-video.webm';
import promoVideoMobilePoster from '../assets/images/promo-video-poster.png';

import launchVideoMp4 from '../assets/videos/launch-video.mp4';
import launchVideoWebm from '../assets/videos/launch-video.webm';
import launchVideoPoster from '../assets/images/launch-video-poster.png';
import launchVideoMobileMp4 from '../assets/videos/launch-video-square.mp4';
import launchVideoMobileWebm from '../assets/videos/launch-video-square.webm';
import launchVideoMobilePoster from '../assets/images/launch-video-square-poster.png';

function playPauseVideo() {
  // source https://benfrain.com/automatically-play-and-pause-video-as-it-enters-and-leaves-the-viewport-screen/
  let videos = document.querySelectorAll("video.autoplay");

  videos.forEach((video) => {
    // We can only control playback without insteraction if video is mute
    // video.muted = true;
    // Play is a promise so we need to check we have it
    let playPromise = video.play();
    if (playPromise !== undefined) {
      playPromise.then((_) => {
        let observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                entry.intersectionRatio !== 1 &&
                !video.paused
              ) {
                video.pause();
              } else if (video.paused) {
                video.play();
              }
            });
          },
          {threshold: 0.2}
        );
        observer.observe(video);
      });
    }
  });
}

const IndexPage = () => {
  useLayoutEffect(() => {
    // playPauseVideo();
  }, []);

  return (
    <Layout>
      <section id="banner">
        <div className="inner">
          <h2>
            Join us this Sunday
            <br />
            1030AM @ Vue Cinema
            <br />
            Liffey Valley
          </h2>
        </div>
        <video
          className="desktop autoplay"
          autoPlay
          loop
          muted
          preload="auto"
          poster={promoVideoPoster}
        >
          <source src={promoVideoWebm} type="video/webm; codecs=vp9,vorbis" />
          <source src={promoVideoMp4} type="video/mp4" />
        </video>
        <video
          className="mobile autoplay"
          autoPlay
          loop
          muted
          preload="auto"
          poster={promoVideoMobilePoster}
        >
          <source src={promoVideoMobileWebm} type="video/webm; codecs=vp9,vorbis" />
          <source src={promoVideoMobileMp4} type="video/mp4" />
        </video>
      </section>

      <section id="two" className="wrapper style2">
        <div className="inner">
          <h2>Jesus in every heart & home</h2>
        </div>
      </section>

      <section id="three" className="wrapper style3 special">
        <video
          className="desktop"
          controls
          loop
          preload="auto"
          poster={launchVideoPoster}
        >
          <source src={launchVideoWebm} type="video/webm; codecs=vp9,vorbis" />
          <source src={launchVideoMp4} type="video/mp4" />
        </video>
        <video
          className="mobile"
          controls
          loop
          preload="auto"
          poster={launchVideoMobilePoster}
        >
          <source src={launchVideoMobileWebm} type="video/webm; codecs=vp9,vorbis" />
          <source src={launchVideoMobileMp4} type="video/mp4" />
        </video>
      </section>

      <section id="four" className="wrapper style4 special">
        <h2>About Us</h2>
        <ul className="actions special">
          <li>
            <a href="/our-story" className="button">
              Our Story
            </a>
          </li>
          <li>
            <a href="/our-vision" className="button">
              Our Vision
            </a>
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default IndexPage;
